@import '../../variables.scss';

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span {
  &.strong {
    font-weight: 800;
  }

  &.medium {
    font-weight: 600;
  }

  &.regular {
    font-weight: 400;
  }

  &.brown {
    color: $brown;
  }
}

@import '../../variables';

// for FavoritesPage.js, ViewingHistoryPage.js
.VP-CarouselLoader,
.VP-TitleLoader,
.VP-ExternalPlayerlLoader {
  margin-bottom: 0.8em;
}

.VP-CarouselLoader {
  height: 8em;
}

.VP-TitleLoader {
  max-width: 400px;
  height: 35px;
}

.VP-ExternalPlayerlLoader {
  aspect-ratio: 16 / 9;
  height: 100%;
}

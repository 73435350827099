@import '../../variables';

.SlickSliderContainer {
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 10px;

  &.Hidden {
    display: none;
  }

  .SliderContent {
    max-height: 40vw;
    overflow: hidden;
  }

  .FullPlayer {
    height: 40vw;

    .vjs-tech {
      object-fit: cover;
    }

    .video-js {
      min-height: auto !important;
    }
  }

  .slick-slider {
    height: 100%;
  }

  .slick-dots {
    bottom: 10%;
    z-index: 2;

    button {
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0 0 2px #000;
      opacity: 0.5;

      &:before {
        display: none;
      }
    }

    .slick-active {
      button {
        opacity: 1;
      }
    }
  }

  .slick-prev {
    left: 5px;
  }

  .slick-next {
    right: 5px;
  }

  .slick-prev,
  .slick-next {
    width: 40px;
    height: 50px;
    z-index: 2;

    @media (max-width: $breakpoint-tablet) {
      & {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      }
    }

    &:before {
      border-bottom: 5px solid $white;
      border-right: 5px solid $white;
      transform: rotate(-45deg);
      display: block;
      width: 10px;
      height: 10px;
      margin: auto;
      opacity: 1;
      font-size: 0;
    }
  }

  .slick-prev {
    &:before {
      transform: rotate(135deg);
    }
  }
}

.SlidePlaceholder {
  height: 15vw;
}

@import '../../../../variables';

.MySection {
  .MoviesScenesGrid {
    padding: 0;
  }

  .MoviesScenesGrid .Grid,
  .MoviesScenesGrid .PlaceholdersGrid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .GP-Inner {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 1em 0;
  }

  @media (max-width: $breakpoint-tablet) {
    .MoviesScenesGrid .Grid,
    .MoviesScenesGrid .PlaceholdersGrid,
    .GP-Inner {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  @media (max-width: $breakpoint-tablet) {
    .MovieBoxCoverStillDisplayInfos .Title,
    .GP-Info {
      color: $white;
    }
  }

  .SectionOpen {
    @media (max-width: $breakpoint-tablet) {
      display: none;

      &.Active {
        display: block;
        max-height: 60vh;
        overflow: auto;
      }

      .CreateNewPlaylistsBtn {
        color: $secondary;
        margin: 0 0 1.3em;
        display: inline-block;
        text-transform: uppercase;
      }
    }
  }

  .NoRecords {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    color: $black;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2;

    .NR-Subtitle {
      font-size: 75%;
    }

    @media (max-width: $breakpoint-tablet) {
      &.PlaylistNoRecords {
        background-color: unset;
        color: $white;
      }
    }

    @media (min-width: $breakpoint-mobile) {
      font-size: 150%;
    }
  }
}

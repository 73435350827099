@import '../../../variables';

.UserPlaylistsPage {
  @media (min-width: $breakpoint-tablet) {
    padding: 1em;
  }

  @media (max-width: $breakpoint-tablet) {
    .GridPlaylist {
      padding: 0.5em;
    }
  }

  .VideosPlaylist {
    margin-bottom: 1em;
  }
}

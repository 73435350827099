@import '../../../variables';

.StudiosPage {
  display: flex;
  flex-direction: column;
  font-family: $font-family-metropolis;
  background: $white;

  & > .SlickSliderContainer .SlidePlaceholder {
    height: 40vw;
  }
}

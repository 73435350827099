@import '../../variables';

.SceneImageContainer {
  cursor: pointer;
  height: 100%;
  aspect-ratio: 16/9;

  .FullPlayer {
    .BaseVideoPlayerCore { 
      .vjs-playlist-player-container {
        overflow: hidden;
      }

      .video-js {
        min-height: auto !important;

        video {
          object-fit: cover;
        }
      }

      .vjs-big-play-button {
        display: none;
      }
    }
  }

  .MouseEventOverlay {
    position: absolute;
    inset: 0;

    @keyframes progress-load {
      0% {
        width: 0;
      }

      100% {
        width: 100%;
      }
    }

    .ProgressContainer {
      height: 3px;
      animation: progress-load 1s forwards;
    }
  }
}
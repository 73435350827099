@import '../../../variables';

.JustAddedPage {
  @media (max-width: $breakpoint-tablet) {
    padding: 1em 0;
  }

  .BoxCoverPlayerDetailsComponent {
    padding: 0 1em;
  }
}

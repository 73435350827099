@import '../../../variables';

.MainDashboardView {
}

.StudioDetailsPageMovieSiteSpecific {
  .PageTitle {
    color: inherit;
    font-size: 120%;
  }
}

@import '../../../../variables';

.MyAccountUserInfos {
  color: $white;

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
    justify-content: center;
  }

  .UserEmail {
    opacity: 0.8;
  }

  .Separator {
    border-left: 1px solid $white;
    margin: 5px 10px 5px;
    height: 1em;
  }

  .UserAccountLink,
  .ModalOpenBtn {
    color: $white;
    text-transform: none;
    text-decoration: underline;

    &:hover {
      color: $gray;
    }
  }
}
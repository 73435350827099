@import '../../variables';

.AiChat {
  position: fixed;
  bottom: 2.5em;
  right: 2em;
  z-index: 5;

  @media(max-width: $breakpoint-mobile) {
    right: 0;
  }

  .BubbleIcon {
    font-size: 2em;
    text-align: right;

    @media(max-width: $breakpoint-mobile) {
      padding: 0 .3em;
    }

    i {
      color: $chatColor;
      text-shadow: 0 0 15px rgba(0, 0, 0, .2);

      @media(min-width: $breakpoint-mobile) {
        &:hover {
          opacity: .8;
        }
      }
    }

    .fa-times-circle {
      background: #fff;
      border-radius: 50%;
      box-shadow: 0 0 15px rgba(0, 0, 0, .4);
      text-shadow: unset;
      font-size: 85%;
    }
  }

  .AiChatLoading {
    font-size: 1.6em;
  }

  .AiChatErrorText,
  .AiChatLoading {
    height: 100%;
    color: $chatColor;
  }

  .AiChatContent {
    width: 100vw;
    max-width: 22em;
    height: 100vh;
    max-height: 22em;
    overflow: auto;
    font-size: 1.2rem;
    background: #f2f2f2;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, .4);

    @media(max-width: $breakpoint-mobile) {
      max-width: 95%;
      margin: auto;
      max-height: 75vh;
    }
  }
}
@import '../variables';

.livechat_button {
  padding: 0;
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  padding-block: 0;
  padding-inline: 0;
  border: 0;
  font-size: unset;
  font-family: $font-family-oswald;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.livechat_button:hover {
  background-color: transparent;
}

.livechat_button:disabled {
  background-color: transparent;
  cursor: not-allowed;
}

/* Hide the chat widget container by default */
#chat-widget-container {
  display: none;
}

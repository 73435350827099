@import '../../../../variables';

.FeaturedRelease {
  .FeaturedRelease-inner {
    padding: 0 10px;

    @media (min-width: $breakpoint-tablet) {
      padding: $default-spacing;
    }

    .DuoBannerSet {
      display: flex;
      gap: 10px;

      @media (min-width: $breakpoint-tablet) {
        gap: $default-spacing;
      }

      .BannerSetView {
        width: 50%;

        .FullPlayer {
          height: 19vw;
        }
      }
    } 
    
    & > .SlickSliderContainer .SlidePlaceholder {
      height: 40vw;
    }
  }
}

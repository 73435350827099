@import '../../../variables';

.SignInPage { 
  padding: 1em;

  .Headline {
    color: $brown;
    padding: 0 0 10px 0;
    text-transform: uppercase;
    text-align: center;
  }

  .SignInButton {
    background-color: $secondary;
  }
}

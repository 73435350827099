@import '../../../variables';
 
.MyAccountPage {
  background-color: $dark-black;

  > .BannerSetView {
    margin: 0 10px;

    &:last-child {
      margin: 10px;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    background-color: $white;

    > .BannerSetView {
      margin: 0 30px;

      &:last-child {
        margin: 30px;
      }
    }
  }
}

@import '../../../variables';

.ChatList {
  .ChatListHeader {
    text-align: center;
    padding: .6em .2em;
    position: sticky;
    top: 0;
    z-index: 1;
    background: $chatColor;
    color: #fff;
  }

  .ChatListItem {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 1em;
    padding: .5em;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, .03);
    }

    .ChatListImageContainer {
      width: 100vw;
      max-width: 4em;
      max-height: 4em;
      overflow: hidden;
      border-radius: 50%;

      @media(max-width: $breakpoint-mobile) {
        max-width: 3em;
        max-height: 3em;
      }

      .ChatListImage {
        max-width: 100%;
        height: auto;
      }
    }

    .ChatListTitle {
      justify-content: space-between;

      span:first-of-type {
        font-weight: 600;
        max-width: 60%; // if name is too long
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      span:last-of-type {
        opacity: .7;
        font-size: 85%;
      }
    }

    .ChatListInfo {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 80%;
    }
  }
}
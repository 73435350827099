@import '../../../../variables';

.PlaylistsPage {
  .PL-Container {
    padding: 0.5em;

    @media (min-width: $breakpoint-tablet) {
      padding: 30px 50px;
    } 

    .PL-Grid {
      display: grid;
      gap: 30px;

      @media (min-width: $breakpoint-tablet) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  .PL-ItemLoaders {
    height: 16vw;
  }
}

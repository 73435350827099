@import '../../../variables';

.ThemesPage {
  display: flex;
  flex-direction: column;
  background: #fff;

  & > .SlickSliderContainer .SlidePlaceholder {
    height: 40vw;
  } 

  .AlphabeticNavigation {
    justify-content: space-between;
    text-transform: uppercase;
    padding: 4px 8px;
    background: $contrast-dark;
    color: $white;
    margin: 0 0 15px;

    .Entry {
      margin: 0 12px;
      color: $gray;
      cursor: pointer;

      &.Active,
      &:hover {
        color: $white;
      }
    }

    .ShowMe {
      color: $white;
      font-weight: 600;
    }
  }

  .AlphabeticView-inner,
  .AlphabeticViewLoadingSkeleton {
    font-family: $font-family-metropolis;
  }
}

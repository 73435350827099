@import '../../variables';

.ImageLoadingSkeleton {
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  background-color: $gray;
  height: 12vw;

  .InnerSkeleton {
    height: 100%;
    animation: defaultImageLoad 1s;
    animation-iteration-count: infinite;

    .ChildSkeleton {
      position: absolute;
      top: 0;
      height: 100%;
      width: 150px;
      background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
    }

    @keyframes defaultImageLoad {
      from {
        transform: translateX(-100%);
      }

      to {
        transform: translateX(100%);
      }
    }
  }
}

@import '../../../variables';

.CreateNewPlaylistsBtn {
  margin-left: 0.5em;
  position: relative;
  color: $white;
  line-height: 1;
  cursor: pointer;

  .CP-text {
    font-size: 80%;

    @media (min-width: $breakpoint-tablet) {
      font-size: 85%;
    }

    &:hover {
      opacity: 0.7;
    }

    i {
      font-size: 70%;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    .PlaylistsPlayerPopup {
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

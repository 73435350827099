@import '../../variables';

.TruncateText {
  display: flex;
  align-items: flex-end;
  text-wrap: wrap;

  .TT-NoWrap {
    white-space: nowrap;
    overflow: hidden;
    max-width: 250px;
  }

  .TT-Item {
    display: inline-block;
    padding-right: 0.6em;
  }

  .TT-ToggleBtn {
    display: inline-block;
    width: 30px;
    height: 20px;
    text-align: center;
    font-size: 15px;
    line-height: 1.3;
    color: $primary;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    &::before {
      content: '...';
    }

    &.CloseBefore {
      background-color: rgba(0, 0, 0, 0.2);
      color: #fff;
      border-radius: 3px;

      &::before {
        content: '\2716';
      }
    }
  }
}

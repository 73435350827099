@import '../../../variables';

.StreamBlocked {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;

  .Wrapper {
    font-family: $font-family-metropolis;
    background-color: $dark-black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    .StreamBlocked-top {
      background-color: $dark-black;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 0;
      margin-right: 0;
      padding: 30px 15px;

      img {
        height: 150px;
        width: 150px;
      }

      .Information {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        font-size: 30px;
        text-align: center;
        .Title {
          color: $brown;
          font-weight: bold;
          font-size: 40px;
        }

        .Following {
          color: $gray;
          letter-spacing: 2px;
        }
      }
    }

    .Content {
      background-color: $white;
      margin: 0 5px 5px 5px;
      display: 'flex';
      flex-direction: 'column';
      .Row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .Separator {
          margin-top: 1px;
          border-left: 2px solid $black;
          margin-left: 20px;
          margin-right: 20px;
          opacity: 0.5;

          &.None {
            border-left: 1px solid transparent;
            margin-left: 5px;
            margin-right: 5px;
          }
        }
        &.Padding {
          padding: 30px 0;
        }
      }

      .Text {
        display: flex;
        justify-content: center;
        font-size: calc(16px + 0.2vw);
        text-align: center;

        &.Link {
          color: $black;
          cursor: pointer;
          &:hover {
            color: $brown;
          }
        }

        &.Bold {
          font-weight: bold;
        }
        &.Uppercase {
          text-transform: uppercase;
        }

        &.Padding {
          padding: 20px 0;
        }
      }
    }

    @media (min-width: $breakpoint-tablet) {
      .StreamBlocked-top {
        flex-direction: row;
        padding: 50px;

        .Information {
          margin-left: 30px;
          text-align: inherit;
        }
      }

      .Content {
        .Row {
          flex-direction: row;
        }

        .Text {
          font-size: calc(20px + 0.2vw);
          &.Padding {
            padding: 30px 0;
          }
        }
      }
    }
  }
}

@import '../../variables';

.SinglePlayer {
  .FullPlayer {

    .HeartButton,
    .PlusButton {
      display: none;
    }
  }

  .PlayerLoader {
    height: 100%;
    background-color: #000;
  }

  .FullPlayer,
  .PlayerLoader {
    aspect-ratio: 16 / 9;
  }
}
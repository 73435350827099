@import '../../../variables';

.MemberDealsPage {
  padding: 1em;
}

.MemberDealsSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: .5em;

  .MemberDealsImport {
    flex: 1 0 30%;

    @media (max-width: $breakpoint-tablet) {
      flex: 1 0 33%;
    }

    &:hover {
      opacity: .8;
    }

    img {
      width: 100%;
      border: solid 1px #cccccc;
      padding: .5em;
    }
  }
}

.ModalPortalContent.MemberDealsModal {
  background-color: $background;
  margin: 0 auto;

  @media (min-width: $breakpoint-tablet) {
    max-width: 60vw;
  }

  @media (max-width: $breakpoint-tablet) {
    width: 90%;
    height: auto;
    padding: 0;
  }

  .ModalPortalContent-Inner {
    max-width: none;
    padding: 0;

    .ModalCloseBtn {
      color: #000;
    }

    .MD-Content {
      text-transform: uppercase;
      font-family: $font-family-metropolis;
      font-size: 110%;

      @media (max-width: $breakpoint-tablet) {
        font-size: 80%;
      }

      .MemberDealsSection {
        overflow: auto;
        padding: 0 .5em;
        max-height: 40vh;
      }

      .dealsModalHeader {
        color: $secondary;
        padding: .8em 0;
      }

      .DealsModalAccount {
        background: #ffffff;
        color: $primary;
        padding: .8em 2em;
        font-weight: bold;
      }

      .MemberDealsModalSeeMoreBtn {
        background: $secondary;
        color: $background;
        border-radius: 18px;
        padding: 10px 40px;
        letter-spacing: -1px;
        font-size: inherit;
        max-width: 15em;
        margin: .5em auto;

        &:hover {
          opacity: .8;
        }
      }
    }
  }
}
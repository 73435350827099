@import '../../../variables';

.SecondaryActions { 
  color: $white; 
  padding: 10px;
  background: $primary-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase; 
  
  .Item.Active,
  .Item.Selected {
    color: $white;
  }

  .Item {
    color: $primary-contrast;
  }

  .Item:not(:first-of-type)::before {
    content: '';
    border: 1px solid #fff;
    margin: 0 0.5em;
    opacity: 0.5;
  }

  .Separator {
    width: 2px;
    height: 16px;
    background-color: $white;
    margin: 0 12px;
  }

  .AlphabeticNavigation {
    padding: 0;

    .Letters {
      justify-content: flex-start;
      overflow: auto;
      display: flex;
      flex-direction: row;
      padding: 5px 0;
      width: fit-content;
      width: -moz-fit-content;
      scroll-behavior: smooth;

      .Letter {
        font-size: 25px;
      }

      .Separator {
        height: auto;
        margin-top: 2px;
        font-size: 20px;
        border: none;
      }
    }
  }
}

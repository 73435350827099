@import '../../../../variables';

.MostPopular {
  padding: 0 0.5em;
  margin: 1em 0;
 
  .MovieView {
    margin: 0 5px;
  }

  .SceneView {
    margin: 0 0 0.8em;
  }

  .MostWatchedCarousel {
    position: relative;

    .slick-prev {
      left: 10px;
      padding: 0 20px;
    }

    .slick-next {
      right: 5px;
      padding: 0 15px;
    }

    .slick-prev,
    .slick-next {
      z-index: 2;
      width: 60px;
      height: 60px;
      border-radius: 50px;
      background-color: #191919;
      opacity: 0.8;

      &:before {
        border-bottom: 8px solid $white;
        border-right: 8px solid $white;
        transform: rotate(-45deg);
        display: block;
        width: 15px;
        height: 15px;
        opacity: 1;
        font-size: 0;
      }
    }

    .slick-prev {
      &:before {
        transform: rotate(135deg);
      }
    }
  }
}

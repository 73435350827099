@import '../../variables';

.LoadingMask {
  position: fixed;
  inset: 0;
  z-index: 20;
  background-color: rgba(211, 211, 211, .7);

  svg {
    fill: $primary;
  }
}
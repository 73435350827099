@import '../../../variables';

.MovieDetailsPage {
  .PageTitle {
    font-family: $font-family-oswald;
    font-weight: 600;
    font-size: 160%;
  }

  @media (min-width: $breakpoint-mobile) {
    .MoviesScenesGrid {
      padding: 0; 
    }
  }

  @media (min-width: $breakpoint-mobile) {
    margin-top: 30px;
    padding: 0 10px 50px;
  }

  @media (min-width: $breakpoint-tablet) {
    padding: 0 20px 50px;
  }
}

@import '../../../variables';

.OriginalsPage {
  .BoxCoverPlayerDetailsComponent,
  .BaseSectionHeader {
    margin: 25px 0;
  }

  .BannerSetView:first-of-type .SlidePlaceholder {
    height: 40vw;
  }

  .OriginalsSlider {
    height: 40vw;

    .slick-dots {
      bottom: 15%;
    }
  }

  .OriginalsSlider,
  .BaseSectionHeader,
  .BoxCoverPlayerDetailsComponent {
    padding: 0 1em;
  }
}

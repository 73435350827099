@import '../../../variables';

.MobileUserActions {
  background-color: $dark-blue;
  padding: 10px;
  color: $white;
  font-size: 1.3rem;
  text-align: center;

  @media (min-width: $breakpoint-tablet) {
    display: none;
  }

  .Separator {
    cursor: inherit;
    margin: 0 12px;
    margin-bottom: -2px;
    height: 24px;
    border-left: 2px solid $white;
    opacity: 0.8;
    opacity: 0.8;
  }

  .SignIn {
    color: $white;
  }
}
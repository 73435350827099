@import '../../../variables';

.ChatMessage {
  flex-direction: column;
  height: 100%;

  .ChatMessageHeader {
    color: #fff;
    background: $chatColor;
    padding: .3em .5em;
    justify-content: space-between;

    .ChatMsgBackBtn {
      padding: .1em .2em;
      font-size: 1.3em;

      &:hover {
        opacity: .8;
      }
    }
  }

  .ChatMsgImg {
    border-radius: 50%;
    margin: 0 .5em;
    vertical-align: middle;
    object-fit: cover;
  }

  .ChatMessageContent {
    flex: 1;
    padding: 1em;
    overflow: auto;
    font-size: 90%;

    .UserMsg {
      text-align: right;
    }

    .BotMsg {
      display: grid;
      grid-template-columns: 1fr 8fr;
      align-items: start;

      .MsgText {
        background: #ebebeb;
      }
    }

    .MsgText {
      display: inline-block;
      background: #e7eefe;
      padding: .3em .8em;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, .1);
      margin: .3em 0;
    }
  }

  .ChatMessageInputContainer {
    position: relative;

    .ChatMessageInput {
      width: 100%;
      border: none;
      border-top: 2px solid rgba(0, 0, 0, .1);
      border-bottom: 2px solid rgba(0, 0, 0, .1);
      padding: .5em 2em .5em .5em;
      outline: none;
      background-color: #fff;
      font: inherit;
      font-size: 90%;
    }

    .ChatMsgSendBtn {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: .6em;
      color: $chatColor;

      &:hover {
        opacity: .8;
      }
    }
  }

  // feedback
  .ChatFeedbackBtn {
    text-align: center;

    span {
      display: inline-block;
      padding: .4em;
      font-size: 80%;
      opacity: .6;

      &:hover {
        opacity: 1;
      }
    }
  }

  .FeedbackTitle {
    font-size: 1.5em;
    margin: .5em 0 1em;
  }

  .FeedbackResponse {
    text-align: center;
    margin: 1em 0 0;

    i {
      font-size: 1.5em;

      &.fa-check {
        color: #009900;
      }
    }

    .BackToFullListBtn {
      display: inline-block;
      margin: .8em 0 0;
      font-size: 115%;

      &:hover {
        opacity: .6;
      }
    }
  }
}
@import '../../../variables.scss';

.SeriesPage {
  .SerieTitle {
    margin: 20px 0;
    font-family: $font-family-metropolis;
    font-size: 160%;
    font-weight: 400;
    color: $secondary;
    text-transform: uppercase;
    padding: 0 0.5em;
  }

  .BoxCoverPlayerDetailsComponent {
    @media (min-width: $breakpoint-tablet) {
      padding: 0 1em;
    }

    margin-bottom: 1em;
  }
}

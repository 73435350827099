@import '../../../variables';

.TopTen {
  .MoviesScenesGrid {
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 0.5em;
    background-color: $top-ten-background;
  }

  .NumberOne {
    cursor: pointer;
    background-color: $top-ten-background;

    @media (max-width: $breakpoint-tablet) {
      padding: 1em 1em 0;
    }
  }

  .MovieBoxCoverStillDisplayInfos .Title {
    color: $white;
  }

  .TopTenImageLoader {
    height: 40vw;
  }
}

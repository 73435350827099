@import '../../../variables';

.MainDashboardHeader {
  box-shadow: $navigation-box-shadow;
  padding: 0.5em;
  position: relative;
  z-index: 5;
  background-color: $white;
  justify-content: space-between;
  font-size: 1.3vw;
  font-family: $font-family-metropolis;

  @media (max-width: $breakpoint-tablet) {
    background-color: $dark-blue;
    padding: 1em;
    font-size: 0.9em;
  }

  @media (min-width: $breakpoint-tablet) {
    .ApplicationLogo {
      margin-right: 1em;
    }
  }

  @media (min-width: $breakpoint-desktop) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: $breakpoint-mobile) {
    padding: 0.6em;
  }

  .NavBar {
    @media (max-width: $breakpoint-tablet) {
      display: none !important;
    }

    .NavBar-Btn {
      margin-right: 15px;
      white-space: nowrap;
      color: $dark-blue;
      padding: 8px 0;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: 400;

      &:hover {
        color: $contrast;
      }
    }

    .HeaderNavCollapseIndicator {
      margin-right: 10px;
      font-size: 120%;
      color: $dark-blue;
    }
  }

  .MobileMenuBtn {
    display: none;
    color: $brown;
    font-size: 24px;

    @media (max-width: $breakpoint-tablet) {
      display: inline-block;
    }
  }

  .AgeVerifiedContainer {
    width: 2em;
    height: 1.8em;
    position: relative;
    margin: 0 0 0 0.6em;
    padding: 0.2em;
    color: $white;
    background: $ageVerifiedIcon;
    border-radius: 0.3em;
    cursor: pointer;

    @media (max-width: $breakpoint-mobile) {
      width: 1.4em;
      height: 1.4em;
    }

    svg {
      vertical-align: middle;
      pointer-events: none; // datalayer click bug
    }

    .AgeVerifiedContent {
      display: none;
      position: absolute;
      right: 0;
      top: 100%;
      z-index: 3;
      width: 100vw;
      max-width: 14em;
      color: $white;
      background: $ageVerifiedIcon;
      padding: 0.5em;
      border-radius: 0.3em;
      text-align: center;
      line-height: 1.5;
    }

    &:hover {
      .AgeVerifiedContent {
        display: block;
      }
    }
  }

  .Separator {
    margin: 0 10px;
    color: $brown;
  }

  .MobileSearchWrapper {
    // BackdropFilterMobile in <MainDashboardHeader />
    background-color: rgba(249, 247, 248, 0.9);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 10px;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100vh;
      pointer-events: none;
      background: rgba(255, 255, 255, 0.5);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
  }

  .MobileSearchBtn {
    display: none;
    width: 40px;
    font-size: 18px;
    color: $white;

    @media (max-width: $breakpoint-tablet) {
      display: inline-block;
    }
  }  
}
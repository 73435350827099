@import '../../variables';

.MovieSceneDetails {
  display: grid;
  gap: 1em 2em;
  padding: 1em 0.5em;
  border-bottom: 1px solid $videoDetailstItems;
  margin-bottom: 1em;
  
  @media (min-width: $breakpoint-tablet) {
    grid-template-columns: 1.5fr 1fr;
    padding: 1em 0;
  }

  .MSD-Info {
    display: grid;
    grid-template-columns: minmax(100px, 110px) 1fr;
    gap: 0 1em;

    @media (min-width: $breakpoint-tablet) {
      grid-template-columns: minmax(100px, 210px) 1fr;
    }
  }

  .MSD-SectionRow {
    padding-bottom: 0.2em;

    @media (min-width: $breakpoint-tablet) {
      padding-bottom: 0.5em;

      &:not(:first-of-type) {
        padding-top: 0.5em;
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $videoDetailstItems;
    }

    .TT-Item {
      text-transform: uppercase; 
      font-size: 75%;

      &:hover {
        opacity: 0.7;

        &::after {
          opacity: 0.5;
        }
      } 

      .TT-Item {
        text-transform: uppercase;
        color: $contrast-dark;
        font-size: 75%;

        &:hover {
          opacity: 0.7;

          &::after {
            opacity: 0.5;
          }
        }

        &::after {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          background: $videoDetailstItems;
          border-radius: 50%;
          margin: 0.1em 0 0.1em 0.5em;
          opacity: 0.4;
        }
      }

      a:last-of-type::after {
        display: none;
      }
    }

    a:last-of-type::after {
      display: none;
    }
  }

  .MSD-SectionTitle,
  .MSD-Summary {
    text-transform: uppercase;
    color: $videoDetailstItems;

    @media (min-width: $breakpoint-tablet) {
      font-size: 90%;
    }
  }

  .MSD-ReadMoreLess {
    color: #000;
    opacity: 0.4;
    font-style: italic;
  }

  .MSD-Clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.ImageComponent {
  img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    color: transparent;
  }

  .ImageComponent-notFound {
    color: rgba(0, 0, 0, 0.6);
    font-size: inherit !important; // coming from player package
    font-weight: inherit !important; // coming from player package
    padding: 1em 0;
  }
}

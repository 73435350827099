@import '../../variables';

.StreamTest {
  padding: 0.5em 0;

  @media (min-width: $breakpoint-tablet) {
    padding: 1em 1em 2em;
  }

  .AccountContainer {
    text-align: right;
    font-family: sans-serif;
    font-size: 95%;
  }
}
@font-face {
  font-family: 'Metropolis';
  src: url('/assets/fonts/metropolis/Metropolis-Regular.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('/assets/fonts/metropolis/Metropolis-Medium.otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('/assets/fonts/metropolis/Metropolis-Bold.otf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

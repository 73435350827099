@import '../../variables';

.NotificationsContainer {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 5em;
  right: 3%;
  z-index: 5;
  max-width: 16em;
  background: #fff;
  padding: 1em;
  border-radius: .2em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .3);
  line-height: 1.1;
  transform: translateY(-1em);

  &.ToggleNotification {
    opacity: 1;
    visibility: visible;
    transition: .2s ease-in-out;
    transform: translateY(0);
  }

  .N-Content {
    color: #616161;
    margin: 0 .5em;
    white-space: nowrap;

    .N-Title {
      margin-bottom: .3em;
    }

    .N-Message {
      font-size: 85%;
      opacity: .8;
    }
  }

  .InfoIcon {
    color: #007fff;
  }

  .ErrorIcon {
    color: #e50000;
  }

  .InfoIcon,
  .ErrorIcon {
    font-size: 1.4rem;
  }

  .CloseIcon {
    opacity: .2;

    &:hover {
      opacity: .4;
    }
  }
}
@import '../../variables';

.ApplicationLogo {
  svg {
    width: 100vw;
    height: auto;
    max-width: 12em;
    vertical-align: middle;
    pointer-events: none;
    margin: 0 auto;

    &.DesktopIcon {
      display: none;
    }

    &.MobileIcon {
      display: block;
    } 

    @media (min-width: $breakpoint-tablet) {  
      max-width: 16em;

      &.DesktopIcon {
        display: block;
      }

      &.MobileIcon {
        display: none;
      }
    }
  }
}

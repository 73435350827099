@import '../../variables';

.SceneImage {
  height: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .ErrorText {
    color: #fff;
    height: 100%;
    background: #000;
    padding: 5px 10px;
    font-size: 110%;
  }
}

.SceneImageLoader {
  height: 100%;
}

@import '../../variables';

.ModalContainer {
  position: relative;
  display: inline-block;

  .ModalOpenBtn {
    cursor: pointer;
    user-select: none;

    &:hover {
      color: $secondary;

      .Arrow {
        border-top: unset;
        border-bottom: 0.3em solid $secondary;
      }
    }

    .Arrow {
      border-top: 0.4em solid $primary;
      margin-left: 5px;
    }
  }
}

.ModalPortalContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 100%;
  text-align: center;
  font-family: $font-family-oswald;
  background: #000;
  overflow: auto;
  padding-top: 30vw;
  height: 100vh;
  white-space: normal;
  font-weight: normal;

  @media (min-width: $breakpoint-tablet) {
    max-width: 20em;
    font-size: 1.5rem;
    margin: .5em;
    padding: 0;
    border: 1px solid rgba(255, 255, 255, .5);
    border-radius: .3em;
    height: auto;
  }

  // used on multiple pages
  &.SignInModal {
    @media (min-width: $breakpoint-tablet) {
      top: 4.5vw;
      right: 4.5vw;
      left: unset;
      transform: unset;
    }
  }

  .SignIn {
    color: $white;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .ModalPortalContent-Inner {
    position: relative; // for close btn
    padding: .5em 1em;
    margin: auto; // center on mobile
    max-width: 22em; // for close btn

    .ModalCloseBtn {
      position: absolute;
      right: 8px;
      top: 8px;
      z-index: 1;
      color: $white;
      font-size: 1.5em;
      cursor: pointer;
      opacity: .7;
      line-height: 1;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.ModalOverlay {
  &::after {
    content: '';
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    inset: 0;
  }
}
@import '../../variables';

.AgeWarningOverlay {
  position: fixed;
  inset: 0; 
  background-size: 100vw;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-color: $white;
  z-index: 1000;

  .Content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    max-width: 28em;
    padding: 1em;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    font-family: $font-family-metropolis;
    font-weight: 600;
    text-align: center;
    line-height: 1.25;
    color: $white;

    @media (max-width: $breakpoint-tablet) {
      max-width: 85vw;
      font-size: 0.85em;
    }

    button {
      cursor: pointer;
      padding: 10px 20px;
      margin: 0.5em;
      border: none;
      border-radius: 5px;
      background-color: $background;
      color: $secondary;
      font-size: 150%;
      font-weight: inherit;
      min-width: 200px;

      @media (max-width: $breakpoint-tablet) {
        min-width: 135px;
      }

      &:hover {
        background-color: darken(#ff4500, 10%);
        color: $background;
      }

      &.EnterBtn:hover {
        background-color: $secondary;
        color: $background;
      }
    }
  }
}

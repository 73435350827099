@import '../../variables';

.SignIn {
  background: rgba(0, 0, 0, 0.05);
  padding: 1em 1em .5em;
  border-radius: 0.5em;
  width: 100%;
  max-width: 20em;
  margin: auto;
  color: #000;

  .InputWrapper {
    background-color: $white;
    color: $font-awesome-icon-color;

    .SignInInput {
      width: 100%;
      margin: 0 .3em;
      padding: 0 .3em;
      outline: none;
      border: none;
      font-family: $font-family-metropolis;
      font-size: 80%;
    }
  }

  .SignInButton {
    color: $black;
    background-color: $secondary;
    font-weight: bold;
    cursor: pointer;
    font: inherit;
    width: 100%;

    &:hover {
      &:not([disabled='disabled']) {
        color: $white;
      }
    }

    & [disabled='disabled'] {
      cursor: not-allowed;
    }
  }

  .InputWrapper,
  .SignInButton {
    border: none;
    text-transform: uppercase;
    border-radius: 0.3em;
    margin-bottom: .5em;
    padding: .4em;
    line-height: 1;
  }

  .JoinHere {
    margin-left: 0.5em;
    text-transform: uppercase;

    &:hover {
      opacity: 0.7;
    }
  }

  .ForgotPswContainer {
    position: relative;
    text-align: center;

    .IconsContainer {
      font-size: 90%;
      color: $gray;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }

    .MemberPWReminder {
      margin: 0;
      padding: 0;
      background: unset;
      line-height: 1;
      max-width: unset;    
    }
  }

  .InvalidDataContainer {
    border-radius: 0.5em;
    padding: .5em;
    background-color: $sign-in-error-background;
    margin-bottom: 1em;
    font-size: .7em;
    color: $white;
    text-align: center;

    .fa-exclamation-triangle {
      font-size: 2em;
    }
  }
}